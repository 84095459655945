import React from 'react';
import { ContentBox } from '../ContentBox';
import contents from '../../content';
import './Footer.css';

const Footer = () => (
  <footer className="appFooter">
    <div className="footerInner">
      <ContentBox round="xl" anchor="contactUs" className='contactUs' titleRule={false} shadow={false} margin={false} padding={false} title={contents['contactUs'].title} content={contents['contactUs'].content} />
    </div>
  </footer>
);

export { Footer };