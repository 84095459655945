import { useState, useEffect } from 'react';

import config from '../config';
import useWindowSize from './useWindowSize';

const useBreakpoint = () => {
  const [devices, setDevices] = useState({
    mobile: false,
    tablet: false,
    desktop: false,
  });

  const winSize = useWindowSize();

  const updateSize = () => {
    const { width } = winSize;
    const { sm, md, lg } = config.breakpoints;
    const isM = width < sm;
    const isT = width < md && width >= sm;
    const isL = width < lg && width >= md;
    const isD = width >= lg;

    setDevices({
      mobile: isM,
      tablet: isT,
      laptop: isL,
      desktop: isD,
    });
  }

  useEffect(() => {
    updateSize();

    return () => {
      updateSize();
    }
    // eslint-disable-next-line
  }, [winSize]);

  return devices;
}

export default useBreakpoint;