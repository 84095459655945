import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const updateSize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', () => { setTimeout(() => { updateSize() }, 10) }, false);

    return () => {
      window.addEventListener('resize', () => { setTimeout(() => { updateSize() }, 10) }, false);
    }
  }, []);

  return screenSize;
}

export default useWindowSize;