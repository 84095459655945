import React from 'react';

import { ContentBox } from '../../components/ContentBox';
import KeyPractices from '../../components/KeyPractices';
import contents from '../../content';

import './Home.css';

const Home = () => (
  <div className="homePage">
    <ContentBox expand="lg" shadow={false} anchor='aboutUs' className='aboutUs' title={contents['aboutUs'].title} content={contents['aboutUs'].content} />
    <ContentBox expand="lg" anchor="keyPractices" className='keyPractices' title={contents['keyPractices'].title}>
      <KeyPractices />
    </ContentBox>
    <div className="row split-8-4">
      <ContentBox round="xl" anchor="industryExposure" className='industryExposure' title={contents['industryExposure'].title} content={contents['industryExposure'].content} />
      <ContentBox round="xl" anchor="keyLinks" className='keyLinks' title={contents['keyLinks'].title}>
        <ol>
          {
            contents['keyLinks'].contents.map((item, i) =>
              <li key={item.content.substr((1*i), ((1*i)+4))}>
                <a href={item.link} rel='noreferrer' target='_blank'>{item.content}</a>
              </li>
            )
          }
        </ol>
      </ContentBox>
    </div>
  </div>
);

export default Home;