import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import ReactHtmlParser from 'react-html-parser';
import 'react-responsive-carousel/lib/styles/carousel.css';

import useBreakpoint from '../../utils/useBreakpoint';

import contents from '../../content';

import './KeyPractices.css';

const KeyPractices = () => {
  const thumbs = () => contents['keyPractices'].contents.map((item) => item.title) ;
  const { mobile } = useBreakpoint();
  
  const texts = contents['keyPractices'].contents.map((item) => (
    <div key={item.title}>
      <h3>{ item.title }</h3>
      {ReactHtmlParser(item.content)}
    </div>
  ));

  return (
    <Carousel renderThumbs={thumbs} className="carouselKeyPractice" dynamicHeight={mobile} showArrows={false} >
      { texts }
    </Carousel>
  )
}

export default KeyPractices;