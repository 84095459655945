import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Hamburger from '../Hamburger';
import useBreakpoint from '../../utils/useBreakpoint';

import './Header.css';

const menuList = [
  {
    name: 'About Us',
    anchor: 'aboutUs',
  },
  {
    name: 'Services',
    anchor: 'keyPractices',
  },
  {
    name: 'Industry Exposure',
    anchor: 'industryExposure',
  },
  {
    name: 'Important Links',
    anchor: 'keyLinks',
  },
  {
    name: 'Contact Us',
    anchor: 'contactUs',
  },
]

const Header = () => {
  const { hash, pathname } = useLocation();
  const selected = hash.split('#')[1] || 'aboutUs';
  const [openBurger, setOpenBurger] = useState(false);

  const onHamburgerClicked = () => {
    setOpenBurger(!openBurger);
  }
  const { desktop } = useBreakpoint();

  let mclass = '';
  if (!desktop) {
    mclass = openBurger ? 'open' : 'close';
  }

  return (
    <header className="appHeader">
      <div className="headerInner">
        <div className="logoWrap">
          <div className="logoBox">
            <div className="logo"></div>
          </div>
          <div className="orgName">
            <h2>Sreeni & Associates</h2>
            <h3>Chartered Accountants</h3>
          </div>
        </div>
        <nav className="nav">
          {pathname !== '/error' &&
            (
              <>
                {
                  !desktop ?
                    (<Hamburger className="burgorIcon" onClick={onHamburgerClicked} />) : null
                }
                {
                  <ul className={`navList noerror ${mclass}`}>
                    {
                      menuList.map(({ anchor, name }) =>
                        <li key={`${name}-${anchor}`} className={`menuItem ${selected === anchor ? 'selected' : ''}`} onClick={onHamburgerClicked}>
                          <a href={`/#${anchor}`}>{name}</a>
                        </li>
                      )
                    }
                  </ul>
                }

              </>
            )
          }
          {pathname === '/error' &&
            (<ul className="navList">
              <li className="menuItem"><Link to={{ pathname: '/' }}>Home</Link></li>
              {/* Add a check to admin */}
              <li className="menuItem"><Link to={{ pathname: '/admin' }}>Admin</Link></li>
            </ul>)
          }
        </nav>
      </div>
    </header>
  )
};

export { Header };