import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { ContentBox } from '../../components/ContentBox';
import contents from '../../content';

const Error = () => {
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 2000);
    // eslint-disable-next-line
  }, []);

  return (
  <div className="errorPage">
    <ContentBox anchor='error' title={contents['error'].title} content={contents['error'].content}>
      <br />
      <h3>Please wait while redirecting......</h3>
      </ContentBox>
  </div>
)};

export default Error;