import React from 'react';
import PropTypes from 'prop-types';

import './Hamburger.css';

const Hamburger = ({
  className,
  onClick,
}) => {
  const onClickBurger = () => {
    onClick();
  };

  return (
    <div className={`hamburger ${className}`} onClick={onClickBurger}>
      <div className="burger"></div>
      <div className="burger"></div>
      <div className="burger"></div>
    </div>
  )
};

Hamburger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

Hamburger.defaultProps = {
  className: '',
  onClick: () => {},
}

export default Hamburger;