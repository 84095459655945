const contents = {
  aboutUs: {
    title: 'About Us',
    content: `<p>Welcome to <span class="orgNameSub">SREENI & ASSOCIATES Chartered Accountants</span>, Audit and Consulting Firm. We provide Services
    in the areas of Advisory, Assurance and Outsourcing to different verticals of clients since 2014. 
    The firm, with rich experience in Business Formation, SME Management, and Taxation, has expertise in areas of practices like, 
    Corporate Laws and Assurance Services. Apart, the firm also practices in other areas of General Practice.</p>
    
    <p>Set up in the year 2014 by CA Sreenivasan, the Firm is registered with the Institute of Chartered Accountants of India (ICAI), 
    one of the Premier Accounting Bodies of the World. CA Sreenivasan is a Fellow Member of the Institute of Chartered Accountants 
    of India and an Associate Member of The Institute of Cost Accountants of India. He has been into professional 
    practice since 2007, and the firm has an overall team experience of 30 years, including experience in Corporate Sector. 
    Our dedicated team, complemented by a vastly experienced Network of Professional Associates 
    support the various requirements of the clientele.</p>
    
    <p>We believe, trust has never been more important. It’s the link that connects your organisation, your people, 
    your customers, your stakeholders and the world. We know that trust isn’t something you can buy. 
    It’s something you earn through every interaction, every experience, every relationship and every outcome delivered. 
    Our professional approach blended with personal touch has earned us an enormous confidence of our clients, which is 
    reflected in an enduring business relationship that we enjoy with them.</p>

    <p>Over the years, guided by our values, we have strengthened our capabilities considerably, cultivated lasting 
    trust and relationships across our business ecosystem, while staying true to our strategic direction and the vision 
    to make a real difference.</p>`
  },
  keyPractices: {
    title: 'Services',
    contents: [
      {
        title: 'Start-up',
        content: `
        <ol class="boxed">
          <li>
            <strong>Start-up Advisory:</strong>
            Assisting the client in deciding the right business form - Company, LLP, Partnership Firm, OPC, or Proprietorship. 
            Assistance in inbound investment into India and formulation of entry strategy in the form of Liaison Office, 
            Branch Office, Subsidiary Company etc. including assistance in making applications to the Reserve Bank of India (RBI) 
            and Foreign Investment Promotion Board (FIPB).
          </li>
          <li>
            <strong>Set-up Business:</strong>
            Complete solution in the space of business setup, incorporation of various forms of legal entities in India,
            Company (Private / Public), Limited Liability Partnerships, Partnership Firms, One Person Companies, Not for 
            Profit Organisations (NPOs), Trusts etc. All post incorporation Statutory registrations like GST, Professional Tax, 
            Establishment Registration etc. with various Regulatory Authorities.
          </li>
        </ol>`
      },
      {
        title: 'Advisory',
        content: `
        <ol class="boxed">
          <li>
            <strong>System Design & Introduction:</strong>
            Design and introduction of financial, costing, management information, management accounting, internal control and 
            internal audit systems. Preparation of comprehensive financial and cost accounting manuals and internal control 
            and internal audit manuals.
          </li>
          <li>
            <strong>CFO Services:</strong>
            Finance Strategy, Cost Management, Working Capital Management, Tax Planning, Business Partnering and Decision Support, 
            Planning, Budgeting and Forecasting. 
          </li>
          <li>
            <strong>Regulatory Compliance:</strong>
            Assisting the management to adhere to various regulatory requirements and related compliance - those framed by 
            the Central Government - Corporate Laws and related regulations, Tax regulation, Foreign Exchange Regulation, 
            State Government Business Regulations, and various sectoral regulations applicable from time to time.
          </li>
        </ol>`
      },
      {
        title: 'Tax',
        content: `
        <ol class="boxed">
          <li>
            <strong>Corporate/Business Tax:</strong>
            Business Tax Review and Advisory, Corporate Tax Planning, Preparation of Tax Returns, 
            and other compliances, Indirect Taxes-GST-Review, Advisory, Preparation of Tax Returns, and other compliances. 
          </li>
          <li>
            <strong>International Tax:</strong>
            Compliance of provisions relating to Withholding Tax, Advisory on Double Taxation Avoidance Agreements, 
            Expatriate/NRI Taxation, Representation before Tax Authorities, Certification of tax to be deducted at source 
            in relation to foreign remittances, Certificate of lower deduction u/197 etc.
          </li>
          <li>
            <strong>Personal Tax:</strong>
            Review, Advisory, Tax Return preparation of HNIs/NRIs, Advisory on Succession Planning, Formation of Family Trust 
            for HNIs, Drafting deeds, Other Agreements.
          </li>
          <li>
            <strong>Representation:</strong>
            Representing the client before various Tax Authorities.
          </li>
        </ol>`
      },
      {
        title: 'Assurance',
        content: `
        <ol class="boxed">
          <li>
            <strong>Statutory Audit:</strong>
            Audit under various Statutes - Audit under Companies Act 2013, Audit under LLP Act, Audit under various 
            Taxation and other Laws.
          </li>
          <li>
            <strong>Internal/Management Audit:</strong>
            Internal Audit or Management Audit is an independent, objective assurance and consulting activity designed to add 
            value and improve an organisation's operations. It improves the effectiveness of risk management, control and 
            governance.
          </li>
          <li>
            <strong>Special Audits:</strong>
            Special Audits cover audits as per specific scope agreed to with the management with an objective 
            of helping them to have an in-depth audit of specified areas like propriety of transactions, investigation into fraud, 
            existence of assets, etc. 
          </li>
          <li>
            <strong>Other Attest Services:</strong>
            Certifications under various Laws-Like Net worth Certificate, Utilisation Certificate, Certificate in Form15CB etc.
          </li>
        </ol>`
      },
      {
        title: 'Outsourcing',
        content: `
        <div class="boxedItem">
          <p>
            Companies often outsource as a way to lower costs, improve efficiencies and gain speed. Companies that decide to 
            outsource rely on the third-party providers' expertise in performing the outsourced tasks to gain such benefits. 
            The underlying principle is that because the third-party provider focuses on that particular task, it is able to do 
            it better, faster and cheaper than the hiring company could.
          </p>
          <p>
            In many situations, start-ups tend to outsource because they may not be able to hire in-house, 
            full-time employees with the specialized skills and experience needed to perform certain jobs.
          </p>
          <p>
            We offer complete outsourcing solution to SMEs in the area of Finance and compliances including Accounting, 
            MIS, Payroll, Setting up Processes etc.
          </p>
        </div>
        `
      },
    ]
  },
  keyLinks: {
    title: `Important Links`,
    contents: [
      {
        link: 'http://icai.org',
        content: 'The Institute of Chartered Accountants of India'
      },
      {
        link: 'https://icmai.in',
        content: 'The Institute of Cost Accountants of India'
      },
      {
        link: 'http://www.icsi.edu/',
        content: 'The Institute of Company Secretaries of India'
      },
      {
        link: 'https://www.tin-nsdl.com/',
        content: 'NSDL - Tax Information Network of Income Tax Department'
      },
      {
        link: 'http://www.sebi.gov.in/',
        content: 'Securities and Exchange Board of India'
      },
      {
        link: 'http://www.incometaxindia.gov.in/',
        content: 'Income Tax Department'
      },
      {
        link: 'https://www.incometax.gov.in/',
        content: 'Income Tax E-filing Portal'
      },
      {
        link: 'https://www.gst.gov.in/',
        content: 'Goods & Service Tax Portal'
      },
      {
        link: 'https://www.cbic.gov.in/ ',
        content: 'Central Board of Indirect Tax & Customs'
      },
      {
        link: 'http://www.mca.gov.in/',
        content: 'Ministry of Corporate Affairs'
      },
      {
        link: 'http://finmin.nic.in/',
        content: 'Ministry of Finance'
      },
      {
        link: 'http://commerce.nic.in/',
        content: 'Ministry of Commerce'
      },
      {
        link: 'http://www.rbi.org.in/',
        content: 'Reserve Bank of India'
      },
    ]
  },
  team: {
    title: 'Team',
    content: `The Firm has an experienced Team that includes expert Consultants that manages different Practice Areas. 
    The Team includes Members of Premier Professional Bodies, like, CAs, CMAs, and Company Secretaries, apart from other 
    Associates, who contribute to the growth of the Firm.`
  },
  location: {
    title: 'Location',
    content: `The Firm operate out of the office located in Koramangala, a prominent Business locality of Bangalore, India.`
  },
  contactUs: {
    title: 'Contact Us',
    content: `<p class="flink">
        The Firm operates out of Koramangala, a prominent Business locality of Bangalore, India.
        Drop a line to connect with us <a href='mailto:connect@saca.co.in'>connect@saca.co.in</a>
      </p>
      <p class="copyRight"><small>All rights reserved to SREENI & ASSOCIATES Chartered Accountants 2021</small></p>`
  },
  error: {
    title: 'Error!!! The requested page is not found',
    content: `The page you are looking for is not available!`
  },
  industryExposure: {
    title: 'Industry Exposure',
    content: `<p>
      Our clients are mostly from small and medium businesses, family business, NGO’s, non-corporate 
      entities and high net-worth individuals.
    </p>
    <ul class="columns-2">
      <li>Technology</li>
      <li>E-Commerce</li>
      <li>Financial Services</li>
      <li>Architect/Interior Designing</li>
      <li>Hospitality</li>
      <li>Education</li>
      <li>Engineering</li>
      <li>Healthcare</li>
      <li>Garments/Textiles</li>
      <li>Contracting </li>
      <li>HR Services</li>
      <li>Security/Staffing </li>
      <li>NPOs.</li>
      <li>HNIs & NRIs</li>
    </ul>`,
  }
}

export default contents;